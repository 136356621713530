import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const ServidorDedicado = lazy(() => import('sections/hero/ServidorDedicado.js'));
const ServicesDedicados = lazy(() => import('sections/services/ServicesDedicados.js'));
const BotonDedicado = lazy(() => import('sections/services/BotonDedicado.js'));
const DedicadoSSD = lazy(() => import('sections/services/DedicadoSSD.js'));
const DedicadoHDD = lazy(() => import('sections/services/DedicadoHDD.js'));
const TecDedicados = lazy(() => import('sections/services/TecDedicados.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {

    

    return (
      <div>
        <Helmet>
          <title>Servidores Dedicados en Colombia: Potencia y confianza</title>
          <meta name="description" content="Descubre nuestros Servidores Dedicados en Pereira, Medellín y Bogotá Colombia, ofreciendo potencia y confianza para tus proyectos. Confiabilidad y rendimiento." />
        </Helmet>
        <Layout>
          <ServidorDedicado />          
          <DedicadoSSD />
          <DedicadoHDD />
          <ServicesDedicados />
          <TecDedicados />          
          <Llamenos />
          <BotonDedicado />          
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`